import React, { useEffect, useRef } from 'react';
import style from './VideoBackground.module.css';

interface VideoBackgroundProps {
  src: string;
}

const VideoBackground: React.SFC<VideoBackgroundProps> = props => {
  const video = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (video.current === null) return;

    console.log('init');

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0, // 0.1 = 10% of video is visible
    };
    const callback = (entries: IntersectionObserverEntry[]): void => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting && (entry.target as HTMLVideoElement).paused) {
          (entry.target as HTMLVideoElement).play();
        } else {
          (entry.target as HTMLVideoElement).pause();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    observer.observe(video.current);

    return (): void => observer.disconnect();
  }, [video]);

  return (
    <>
      <div className={style.overlay}></div>
      <video ref={video} className={style.videoContainer} playsInline={true} muted={true} autoPlay={true} loop={true}>
        <source src={props.src} type="video/mp4" />
      </video>
    </>
  );
};

export default VideoBackground;
